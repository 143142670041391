import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.input
import react.useState

external interface CounterProps : Props {
    var initialCount: Int
}

var counter = FC<CounterProps> { props ->
    var count by useState(props.initialCount)
    div {
        h2 {
            +"Count: $count"
        }
        input {
            type = InputType.range
            min = 0.0
            max = 100.0
            step = 1.0
            onChange = {event ->
                count = event.target.value.toInt()
            }
        }
    }

    
}