import csstype.px
import csstype.rgb
import kotlinext.js.getOwnPropertyNames
import kotlinx.browser.window
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import react.FC
import react.Props
import react.create
import react.css.css
import react.dom.html.InputType
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.input
import react.useState

@Serializable
data class jsonJokes(
    val fat: List<String>,
    val stupid: List<String>,
    val ugly: List<String>,
    val nasty: List<String>,
    val hairy: List<String>,
    val bald: List<String>,
    val old: List<String>,
    val poor: List<String>,
    val short: List<String>,
    val skinny: List<String>,
    val tall: List<String>,
    val like: List<String>
) {
    operator fun get(s: String): List<String> {
        return when (s) {
            "fat" -> fat
            "stupid" -> stupid
            "ugly" -> ugly
            "nasty" -> nasty
            "hairy" -> hairy
            "bald" -> bald
            "old" -> old
            "poor" -> poor
            "short" -> short
            "skinny" -> skinny
            "tall" -> tall
            "like" -> like
            else -> fat
        }
    }
}

external interface MamaJoke : Props {
    var joke: String
}

val Welcome = FC<MamaJoke> { joke ->
    var joke by useState(joke.joke)
    div {
        h1 { +"Yo mam joke generator"}
        h2 {
            +"$joke"
        }
        button {
            +"Generate joke"
            onClick ={
                println("Generate joke")
                window.fetch("https://raw.githubusercontent.com/joshbuchea/yo-mama/master/jokes.json")
                    .then {
                        it.text()
                    }
                    .then {
                        val jokesJSON = Json {ignoreUnknownKeys = true}
                        val jokes = jokesJSON.decodeFromString<jsonJokes>(it)
                        val names = jokes.getOwnPropertyNames()
                        println(names)
                        val randomName = names[(0..names.size).random()]
                        if (names != undefined) {
//                            joke = jokes[randomName]
                            val randomJokes: List<String> = jokes[randomName]
                            val randomJoke = randomJokes[(0..randomJokes.size).random()]
                            joke = randomJoke
                            println(randomJoke)
                        } else {
                            joke = "No jokes found"
                            println(joke)
                        }
//                        val randomJokes = jokes[randomName] as List<*>
//                        val randomJoke = randomJokes[(0..randomJokes.size).random()]

//                        println(randomJoke)
//                        joke = it.random()
                    }
            }
        }
    }
}
